import { ADD_SESSIONS, ADD_COURSES, ADD_USER, MSG_STATUS, MSG_MESSAGE, MSG_CLOSE, LEAVE_BALANCE, DEPT_SUBJECT } from "../constants/action-types";
const initialState = {
    courses: [],
    sessions: [],
    user: {},
    msg_status: false,
    leaveBalance: [],
    subjects: [],
    msg_message: '',
};
function rootReducer(state = initialState, action) {


    if (action.type === ADD_COURSES) {
        return Object.assign({}, state, {
            courses: action.payload
        });
    }


    if (action.type === ADD_SESSIONS) {
        return Object.assign({}, state, {
            sessions: action.payload
        });
    }

    if (action.type === ADD_USER) {
        return Object.assign({}, state, {
            user: action.payload
        });
    }

    if (action.type === DEPT_SUBJECT) {
        return Object.assign({}, state, {
            subjects: action.payload
        });
    }


    if (action.type === MSG_STATUS) {
        return Object.assign({}, state, {
            msg_status: action.payload
        });
    }

    if (action.type === MSG_MESSAGE) {
        return Object.assign({}, state, {
            msg_message: action.payload
        });
    }

    if (action.type === MSG_CLOSE) {
        return Object.assign({}, state, {
            msg_status: false,
            msg_message: ''
        });
    }

    if (action.type === LEAVE_BALANCE) {
        return Object.assign({}, state, {
            leaveBalance: action.payload
        });
    }

    return state;
}
export default rootReducer;