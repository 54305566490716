import React, { Component } from 'react'
import { withStyles, Typography, Card, CardContent, CardHeader, Button, Divider, Grid } from '@material-ui/core';
import { PostData } from '../../../api/service';
import ReactToPrint from 'react-to-print';

const styles = theme => ({

})

class ReceiptPrint extends Component {
    state = {
        data: [],
        isLoaded: false,
        receipt_no: ''
    }

    __fetchReceiptData = (receipt_no) => {
        let d = {
            receipt_no: receipt_no
        }


        PostData(`/admission/getreceiptsbyreceiptno`, d)
            .then((resp) => {
                //console.log(resp);
                if (Array.isArray(resp) && resp.length > 0) {
                    this.setState({
                        data: resp[0],
                        isLoaded: true
                    })
                }

            })
    }

    componentDidMount() {
        
        this.setState({
            receipt_no: this.props.receipt_no
        }, () => this.__fetchReceiptData(this.props.receipt_no))
    }

    render() {
        let { classes } = this.props;
        return (
            <div>
             
                <Card>
                    <CardHeader
                        title={`Print Receipt`}
                    />
                    <CardContent>

                        <ReactToPrint
                            trigger={() =>
                                <Button
                                    variant="outlined"
                                    color="primary"

                                >
                                    Print
                        </Button>
                            }
                            ref={el => this.buttonRef = el}
                            content={() => this.componentRef}
                            pageStyle={{ margin: '10px' }}
                            copyStyles={true}
                        />

                        <Button
                            onClick={() => window.location.reload()}
                        >
                            GO BACK
                        </Button>

                        <br />
                        <br />

                        {this.state.isLoaded ? (<div ref={el => (this.componentRef = el)}>
                            <AdmissionForm
                               type_p="STUDENT_COPY"
                                {...this.state.data}
                            />

                            <div style={{ pageBreakAfter: 'always' }}></div>

                            <AdmissionForm
                                type_p="OFFICE_COPY"
                                {...this.state.data}
                            />
                            <div style={{ pageBreakAfter: 'always' }}></div>

                            {/* <AdmissionForm
                                type_p="BANK_COPY"
                                {...this.state.data}
                            /> */}
                        </div>) : null}
                    </CardContent>
                </Card>
            </div>
        )
    }
}

export default withStyles(styles)(ReceiptPrint)

class AdmissionForm extends Component {

    showSubjects = (subjects) => {
        let i = [];
        if (Array.isArray(subjects) && subjects.length > 0) {
            i = subjects.map((el, index) =>
                el.label
            )
        }

        return i.join(',');
    }
    render() {
        let total_marks = 0;
        let marks_secured = 0;
        return (
            <div className="admission-form">
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="20%">
                                <img
                                    alt="logo"
                                    src={require('./../../../assets/logo.png')}
                                    style={{ width: '50%' }}
                                />
                            </td>

                            <td width="60%" align="center">
                                <Typography
                                    variant="h6"
                                >
                                    {this.props.type_p === 'STUDENT_COPY' ? `STUDENT COPY`: null}

                                    {this.props.type_p === 'OFFICE_COPY' ? `OFFICE COPY` : null}

                                    {this.props.type_p === 'BANK_COPY' ? `BANK COPY` : null}
                                </Typography>
                                <Typography
                                    variant="h5"
                                >
                                    Nabajyoti College, Kalgachia
                                </Typography>
                                <Typography
                                    variant="body2"
                                >

                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                    Assam
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {/* {this.props.type == 1 ? 'Re-Admission' : 'Admission' }  */}
                                    Payment Receipt
                                </Typography>
                                <Typography
                                    variant="subheading"
                                >
                                </Typography>
                            </td>


                            <td width="20%" align="center">
                                <Typography
                                    variant="subtitle2"
                                >
                                    Receipt No. : <b>{this.props.receipt_no}</b> <br />

                                    Date : <b>{this.props.date}</b>
                                </Typography>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <Divider />
                <br />



                <Grid container spacing={24}>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <table width="100%">
                            <tbody>
                                <tr>
                                    {/* <td width="30%">
                                        <Typography

                                        >
                                            Form No : <b>{this.props.form_id}</b>
                                        </Typography>
                                    </td> */}


                                    <td width="30%">
                                        <Typography
                                            variant=""
                                        >
                                            Class:  <b>{this.props.course} {this.props.stream} {this.props.course_type}</b>
                                        </Typography>
                                    </td>

                                    <td width="20%">
                                        <Typography
                                            variant=""
                                        >
                                            {(this.props.course === 'HSS' || this.props.course === 'HSA') ? 'Year' : 'Semester'}:  <b>{this.props.semester}</b>
                                        </Typography>
                                    </td>

                                    <td width="20%">
                                        <Typography
                                            variant=""
                                        >
                                            Roll No:  <b>{this.props.roll_no}</b>
                                        </Typography>
                                    </td>

                                </tr>

                                <tr>
                                    <td width="30%">
                                        <Typography

                                        >
                                            Student's Name : <b>{this.props.name}</b>
                                        </Typography>
                                    </td>

                                   
                                    <td width="70%" colSpan="3">
                                        <Typography
                                            variant=""
                                        >
                                            Father's Name:  <b>{this.props.father_name}</b>
                                        </Typography>
                                    </td>
                                </tr>



                            </tbody>
                        </table>

                    </Grid>



                </Grid>

                <Divider />
                <br />



                <table width="100%" className="table">
                    <thead>
                        <tr>
                            <td width="10%">
                                <Typography>
                                    #
                                </Typography>
                            </td>

                            <td width="60%">
                                <Typography>
                                    Fees Head
                                </Typography>
                            </td>
                            <td width="30%" align="right">
                                <Typography>
                                    Fees Amount
                                </Typography>
                            </td>
                        </tr>
                    </thead>

                    {Array.isArray(this.props.fees) && this.props.fees.length > 0 ? (
                        <tbody>



                            {Array.isArray(this.props.fees) && this.props.fees.map((el, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            {index + 1}
                                        </td>
                                        <td>
                                            <Typography

                                            >
                                                {el.sm_head}
                                            </Typography>
                                        </td>
                                        <td align="right">
                                            <Typography

                                            >
                                                {el.sm_amount}
                                            </Typography>
                                        </td>
                                    </tr>
                                )
                            })}

                            <tr>
                                <td colSpan="2" align="right">
                                    <Typography>
                                        Amount Paid
                                    </Typography>
                                </td>
                                <td align="right">
                                    <Typography
        style={{fontWeight: 700, fontSize: 16}}
                                    >
                                        {this.props.amount_paid}
                                    </Typography>
                                </td>
                            </tr>
                            {/* <tr>
                                <td colSpan="2" align="right">
                                    <Typography>
                                        Mode of Payment
                                    </Typography>
                                </td>
                                <td align="right">
                                    <Typography

                                    >
                                        {this.props.mop}
                                    </Typography>
                                </td>
                            </tr> */}
                        </tbody>

                    ) : null}

                </table>

                <br />
                <br />
                <br />

                {/* {this.props.is_free_admission === 1 ? (
                    <div>
                        <Typography
                            variant="body2"
                        >
                            Your fee is waived as per Govt. of Assam Guidelines
                    </Typography>
                    </div>
                ) : null} */}

                <div align="right">

                    <Typography
                        variant="body2"
                    >
                        Cash Collected By
                    </Typography>

                </div>

                <br />


                <div align="center" style={{fontSize: 12}}>
                        
                            Collected by <b>{this.props.collected_by}</b> on <b>{this.props.collected_on}</b>
                   
                    </div>








            </div>
        )
    }
}


