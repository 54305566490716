import React, { useState, useEffect } from 'react'
import Clock from 'react-clock';

export default function NewClock() {
    const [d, setD] = useState(new Date());

    useEffect(() => {
        setInterval(() => startClock(), 1000)
    }, [])

    function startClock() {
        setD(new Date());
    }
    return (
        <div>
            <Clock
                value={d}
                size={80}
            />
        </div>
    )
}
