export const APP_NAME = "NABAJYOTI_COLLEGE_TEACHER";
export const APP_GOOD_NAME = "NABAJYOTI COLLEGE";
export const DEFAULT_COLOR = "#2196F3";
export const DRAWER_WIDTH = 180;

export const BaseURL =
  process.env.NODE_ENV === "production"
    ? "https://api.nabajyoticollege.ac.in/public"
    : "http://localhost/nabajyoti_college/api/public";
export const UploadPath =
  process.env.NODE_ENV === "production"
    ? "https://api.nabajyoticollege.ac.in/src/uploads"
    : "http://localhost/nabajyoti_college/api/src/uploads";

export const ACCESS_CONTROL = [
  {
    text: "ACCOUNTS",
    value: "ACC",
  },
  {
    text: "ADMISSION",
    value: "ADM",
  },
  {
    text: "FEES COLLECTION",
    value: "FEE",
  },
  {
    text: "REPORTS",
    value: "REP",
  },
  {
    text: "LEAVE-PRINCIPAL",
    value: "LEAVEPRINCE",
  },
];

export const COURSE_TYPE_LIST = ["HONOURS", "REGULAR"];
export const GENDER_LIST = ["MALE", "FEMALE", "OTHERS"];
export const CASTE_LIST = ["GENERAL", "ST(P)", "ST(H)", "SC", "OBC"];
export const RELIGION_LIST = [
  "HINDUISM",
  "ISLAM",
  "CHRISTIANISM",
  "JAINISM",
  "SIKKHISM",
];

export const MERIT_LIST_TYPE = [
  {
    text: "GENERAL_MERIT_LIST_001",
    key: "GENERAL_MERIT_LIST_001",
  },
  {
    text: "GENERAL_MERIT_LIST_002",
    key: "GENERAL_MERIT_LIST_002",
  },
  {
    text: "GENERAL_MERIT_LIST_003",
    key: "GENERAL_MERIT_LIST_003",
  },
  {
    text: "OBC_MERIT_LIST_001",
    key: "OBC_MERIT_LIST_001",
  },
  {
    text: "OBC_MERIT_LIST_002",
    key: "OBC_MERIT_LIST_002",
  },
  {
    text: "OBC_MERIT_LIST_003",
    key: "OBC_MERIT_LIST_003",
  },
  {
    text: "STP_MERIT_LIST_001",
    key: "STP_MERIT_LIST_001",
  },
  {
    text: "STP_MERIT_LIST_002",
    key: "STP_MERIT_LIST_002",
  },
  {
    text: "STP_MERIT_LIST_003",
    key: "STP_MERIT_LIST_003",
  },
  {
    text: "STH_MERIT_LIST_001",
    key: "STH_MERIT_LIST_001",
  },
  {
    text: "STH_MERIT_LIST_002",
    key: "STH_MERIT_LIST_002",
  },
  {
    text: "STH_MERIT_LIST_003",
    key: "STH_MERIT_LIST_003",
  },
  {
    text: "SC_MERIT_LIST_001",
    key: "SC_MERIT_LIST_001",
  },
  {
    text: "SC_MERIT_LIST_002",
    key: "SC_MERIT_LIST_002",
  },
  {
    text: "SC_MERIT_LIST_003",
    key: "SC_MERIT_LIST_003",
  },
  {
    text: "EWS_MERIT_LIST_001",
    key: "EWS_MERIT_LIST_001",
  },
  {
    text: "QUOTA_MERIT_LIST_001",
    key: "QUOTA_MERIT_LIST_001",
  },
];

export const FEES_STRUCTURE = [
  {
    type: "UNIVERSITY FEE",
    subTypes: [
      {
        type: "University Fees: Affiliation & Inspection Fees",
        likeables: [
          "University Fees: Affiliation & Inspection Fees",
          "University Fee: Affiliation & Inspection Fee",
        ],
      },
      {
        type: "University Fees: Registration Fee (for 1st Year Only)",
        likeables: [
          "University Fees: Registration Fee (for 1st Year Only)",
          "University Fee: Registration Fee (for 1st Year Only)",
        ],
      },
      {
        type: "University Fees:: Enrollment Fee",
        likeables: [
          "University Fees:: Enrollment Fee",
          "University Fee: Enrollment Fee",
        ],
      },
    ],
  },
  {
    type: "COUNCIL FEE",
    subTypes: [
      {
        type: "COUNCIL FEE : Registration Fee",
        likeables: ["COUNCIL FEE : Registration Fee"],
      },
      {
        type: "COUNCIL FEE : Enrollment Fee",
        likeables: ["COUNCIL FEE : Enrollment Fee"],
      },
    ],
  },
  {
    type: "ESTABLISHMENT",
    subTypes: [
      {
        type: "Establishment: Renovation & Repairing old buildings",
        likeables: [
          "Establishment: Renovation & Repairing old buildings",
          "ESTABLISHMENT : Renovation & Repairing old buildings",
        ],
      },
      {
        type: "Establishment: Desk Bench and College Furniture",
        likeables: [
          "Establishment: Desk Bench and College Furniture",
          "ESTABLISHMENT : Desk Bench and College Furniture",
        ],
      },
      {
        type: "Establishment: Computer Purchase and Annual Maintenance",
        likeables: [
          "Establishment: Computer Purchase and Annual Maintenance",
          "ESTABLISHMENT : Computer Purchase and Annual Maintenance",
        ],
      },
      {
        type: "Establishment: Toilet repairing & Cleaning",
        likeables: [
          "ESTABLISHMENT : Toilet Repairing & Cleaning",
          "Establishment: Toilet repairing & Cleaning",
        ],
      },
      {
        type: "Establishment: Boys & Girls Common Room",
        likeables: [
          "Establishment: Boys & Girls Common Room",
          "ESTABLISHMENT : Boys & Girls Common Room",
        ],
      },
      {
        type: "Establishment: CCTV & Security",
        likeables: [
          "Establishment: CCTV & Security",
          "ESTABLISHMENT : CCTV & Security",
        ],
      },
      {
        type: "Establishment: Campus beautification, Gardening",
        likeables: [
          "Establishment: Campus beautification, Gardening",
          "ESTABLISHMENT : Campus Beautification, Gardening",
        ],
      },
      {
        type: "Establishment: Internal Road and disaster fund",
        likeables: [
          "Establishment: Internal Road and disaster fund",
          "ESTABLISHMENT : Internal Road and disaster fund",
        ],
      },
    ],
  },
  {
    type: "LABORATORY",
    subTypes: [
      {
        type: "Laboratory: Equipment, Chemicals etc.",
        likeables: [
          "Laboratory: Equipment, Chemicals etc.",
          "LABORATORY : Equipments, Chemicals etc",
          "Laboratory:Equipments, Chemicals etc",
        ],
      },
    ],
  },
  {
    type: "ELECTRICITY",
    subTypes: [
      {
        type: "Electricity: Power",
        likeables: [
          "Electricity: Power",
          "ELECTRICITY : Power",
          "Electricity:Power",
        ],
      },
      {
        type: "Electricity: Generator, Fuel, Fan-Bulb Purchase & Repairing",
        likeables: [
          "Electricity: Generator, Fuel, Fan-Bulb Purchase & Repairing",
          "ELECTRICITY : Generator, Fuel, Fan-Bulb Purchase & Repairing",
          "Electricity:Generator Fuel, Fan-Bulb Purchase & Repairing",
        ],
      },
    ],
  },
  {
    type: "LIBRARY",
    subTypes: [
      {
        type: "Library: New Book Purchase",
        likeables: [
          "Library: New Book Purchase",
          "LIBRARY : New Book Purchase",
          "Library:Purchase of New Books",
        ],
      },
      {
        type: "Library: Library & Library Software Updating",
        likeables: [
          "Library: Library & Library Software Updating",
          "LIBRARY : Library & Lib. Software updating",
          "Library:Updatation of Library and Lib Software updating/Purchase and repairing of furniture",
        ],
      },
    ],
  },
  {
    type: "CONTINGENCY",
    subTypes: [
      {
        type: "Contingency: Stationary, TA to University",
        likeables: [
          "Contingency: Stationary, TA to University",
          "CONTINGENCY : Stationary, TA to University",
          "Contingency:Stationary, TA to University,Boards, Councils, DHE and other official works",
        ],
      },
    ],
  },
  {
    type: "DEVELOPMENT",
    subTypes: [
      {
        type: "Development: Academic Seminar",
        likeables: [
          "Development: Academic Seminar",
          "DEVELOPMENT : Academic Seminar",
        ],
      },
      {
        type: "Development: Quality(NAAC)",
        likeables: ["Development: Quality(NAAC)"],
      },
      {
        type: "Development: Guest Lecturer",
        likeables: ["Development: Guest Lecturer"],
      },
      {
        type: "Development: : Soft & Hard Skill Development",
        likeables: [
          "Development: : Soft & Hard Skill Development",
          "DEVELOPMENT : Soft & Hard Skill Development",
        ],
      },
      {
        type: "Development: Sports Facility & Training",
        likeables: [
          "Development: Sports Facility & Training",
          "DEVELOPMENT : Sports facility & Training",
        ],
      },
      {
        type: "Development: Cultural Facility & Workshop",
        likeables: [
          "Development: Cultural Facility & Workshop",
          "DEVELOPMENT : Cultural Facility and workshop",
        ],
      },
    ],
  },
  {
    type: "CONTINUOUS EVALUATION",
    subTypes: [
      {
        type: "Continuous Evaluation: Examination",
        likeables: [
          "Continuous Evaluation: Examination",
          "CONTINUOUS EVALUATION : Examination",
        ],
      },
      {
        type: "Continuous Evaluation:Conduct of Internal Examinations/ Project for Internal assessment",
        likeables: [
          "Continuous Evaluation:Conduct of Internal Examinations/ Project for Internal assessment",
        ],
      },
    ],
  },
  {
    type: "STUDENTS RELATED FEES",
    subTypes: [
      {
        type: "Student Related Fee: NCC/NSS/Scout/Red Cross",
        likeables: [
          "Student Related Fee: NCC/NSS/Scout/Red Cross",
          "STUDENTS RELATED FEES : NCC/NSS/Scout/Red Cross",
          "Students related fee:NCCNSS/Scout/Red Cross",
        ],
      },
      {
        type: "Student Related Fee: I Cards",
        likeables: [
          "Student Related Fee: I Cards",
          "STUDENTS RELATED FEES : I Cards",
          "Students related fee:ID Card",
        ],
      },
      {
        type: "Student Related Fee: Magazine",
        likeables: [
          "Student Related Fee: Magazine",
          "STUDENTS RELATED FEES : Magazine",
          "Students related fee:Magazine",
        ],
      },
      {
        type: "Student Related Fee: Union Fees",
        likeables: [
          "Student Related Fee: Union Fees",
          "Students related fee:Union fee",
          "STUDENTS RELATED FEES : Union Fees"
        ],
      },
      {
        type: "Student Related Fee: Games & Sports Competition",
        likeables: [
          "Student Related Fee: Games & Sports Competition",
          "STUDENTS RELATED FEES : Games & Sports Competition",
          "Students related fee:Games & Sports Competition"
        ],
      },
      {
        type: "Student Related Fee: Festival",
        likeables: [
          "Student Related Fee: Festival",
          "STUDENTS RELATED FEES : Festival",
          "Students related fee:College Festivals",
        ],
      },
      {
        type: "Student Related Fee: Cultural & Drama & Music",
        likeables: [
          "Student Related Fee: Cultural & Drama & Music",
          "STUDENTS RELATED FEES : Cultural & Drama & Music",
          "Students related fee:Culture& Drama & Music",
        ],
      },
      {
        type: "Student Related Fee: Debate",
        likeables: [
          "Student Related Fee: Debate",
          "STUDENTS RELATED FEES : Debate",
          "Students related fee:Debate/Quiz",
        ],
      },
      {
        type: "Student Related Fee: Youth Festival",
        likeables: [
          "Student Related Fee: Youth Festival",
          "STUDENTS RELATED FEES : Youth Festival",
          "Students related fee:Youth Festival",
        ],
      },
      {
        type: "Students related fee:Students Welfare",
        likeables: ["Students related fee:Students Welfare"],
      },
      {
        type: "Students related fee:Celebration International Days/National and State Festivals",
        likeables: [
          "Students related fee:Celebration International Days/National and State Festivals",
        ],
      },
    ],
  },
  {
    type: "INFRASTRUCTURE/ FACILITY MAINTENANCE",
    subTypes: [
      {
        type: "Infrastructure/ Facility Maintenance:Renovation & repairing of old Building",
        likeables: [
          "Infrastructure/ Facility Maintenance:Renovation & repairing of old Building",
        ],
      },
      {
        type: "Infrastructure/ Facility Maintenance:Desk-Bench and college, furniture",
        likeables: [
          "Infrastructure/ Facility Maintenance:Desk-Bench and college, furniture",
        ],
      },
      {
        type: "Infrastructure/ Facility Maintenance:Installation and maintenance Smart Class Room",
        likeables: [
          "Infrastructure/ Facility Maintenance:Installation and maintenance Smart Class Room",
        ],
      },
      {
        type: "Infrastructure/ Facility Maintenance:Purchase and maintenance of Computer / IT gadgets",
        likeables: [
          "Infrastructure/ Facility Maintenance:Purchase and maintenance of Computer / IT gadgets",
        ],
      },
      {
        type: "Infrastructure/ Facility Maintenance:Toilet Repairing & Cleaning (Sanitation)",
        likeables: [
          "Infrastructure/ Facility Maintenance:Toilet Repairing & Cleaning (Sanitation)",
        ],
      },
      {
        type: "Infrastructure/ Facility Maintenance: Boys & Girls Common Room",
        likeables: [
          "Infrastructure/ Facility Maintenance: Boys & Girls Common Room",
        ],
      },
      {
        type: "Infrastructure/ Facility Maintenance:CCTV & Security",
        likeables: ["Infrastructure/ Facility Maintenance:CCTV & Security"],
      },
      {
        type: "Infrastructure/ Facility Maintenance: Campus beautification,Gardening ( Green Campus)",
        likeables: [
          "Infrastructure/ Facility Maintenance: Campus beautification,Gardening ( Green Campus)",
        ],
      },
      {
        type: "Infrastructure/ Facility Maintenance:Repairing development of internal road/ communication",
        likeables: [
          "Infrastructure/ Facility Maintenance:Repairing development of internal road/ communication",
        ],
      },
    ],
  },
  {
    type: "QUALITY ENHANCEMENT",
    subTypes: [
      {
        type: "Quality Enhancement:Academic Seminar/Publication",
        likeables: ["Quality Enhancement:Academic Seminar/Publication"],
      },
      {
        type: "Quality Enhancement:IQAC (NAAC)",
        likeables: ["Quality Enhancement:IQAC (NAAC)"],
      },
      {
        type: "Quality Enhancement:Faculty Development",
        likeables: ["Quality Enhancement:Faculty Development"],
      },
      {
        type: "Quality Enhancement:Invited Lecturer/ Resource person",
        likeables: ["Quality Enhancement:Invited Lecturer/ Resource person"],
      },
      {
        type: "Quality Enhancement:Skill Development and placements",
        likeables: [
          "Quality Enhancement:Skill Development and placements",
          "Quality Enhancement:Skill Development and  placements",
        ],
      },
      {
        type: "Quality Enhancement:Sports facility and training",
        likeables: ["Quality Enhancement:Sports facility and training"],
      },
      {
        type: "Quality Enhancement:Cultural facility and workshop",
        likeables: ["Quality Enhancement:Cultural facility and workshop"],
      },
      {
        type: "Quality Enhancement:Infrastructure development",
        likeables: ["Quality Enhancement:Infrastructure development"],
      },
    ],
  },
  {
    type: "DISASTER MANAGEMENT",
    subTypes: [
      {
        type: "Disaster Management:Management of disaster Maintenance and repairing damage caused by flood, erosion storm etc",
        likeables: [
          "Disaster Management:Management of disaster Maintenance and repairing damage caused by flood, erosion storm etc",
        ],
      },
    ],
  },
  {
    type: "RESEARCH, INNOVATION, EXTENSION & SKILL DEVELOPMENT",
    subTypes: [
      {
        type: "Research, Innovation, Extension & Skill Development:Research Guidance, Project Preparation, Field Work etc.",
        likeables: [
          "Research, Innovation, Extension & Skill Development:Research Guidance, Project Preparation, Field Work etc.",
        ],
      },
      {
        type: "Research, Innovation, Extension & Skill Development:Start Up,Incubation, Internship",
        likeables: [
          "Research, Innovation, Extension & Skill Development:Start Up,Incubation, Internship",
        ],
      },
      {
        type: "Research, Innovation, Extension & Skill Development:Community Service etc.",
        likeables: [
          "Research, Innovation, Extension & Skill Development:Community Service etc.",
        ],
      },
      {
        type: "Research, Innovation, Extension & Skill Development:Soft Skills & Skills for Employability & Entrepreneurship",
        likeables: [
          "Research, Innovation, Extension & Skill Development:Soft Skills & Skills for Employability & Entrepreneurship",
        ],
      },
    ],
  },
  {
    type: "HOSTEL FEE",
    subTypes: [
      {
        type: "Form",
        likeables: ["Form"],
      },
      {
        type: "Hostel Admission Fees",
        likeables: ["Hostel Admission Fees"],
      },
      {
        type: "Hostel Seat Rent",
        likeables: ["Hostel Seat Rent"],
      },
      {
        type: "Maintenance",
        likeables: ["Maintenance"],
      },
      {
        type: "Light Charge",
        likeables: ["Light Charge"],
      },
      {
        type: "Fan Charge",
        likeables: ["Fan Charge"],
      },
      {
        type: "Caution Money",
        likeables: ["Caution Money"],
      },
      {
        type: "Hostel Development Fees",
        likeables: ["Hostel Development Fees"],
      },
    ],
  },
  {
    type: "OTHER FEE",
    subTypes: [
      {
        type: "Character Ceretificate",
        likeables: ["Character Ceretificate"],
      },
      {
        type: "Character Certificate (Duplicate)",
        likeables: ["Character Certificate (Duplicate)"],
      },
      {
        type: "Testimonial Certificate (Duplicate)",
        likeables: ["Testimonial Certificate (Duplicate)"],
      },
      {
        type: "Testimonial",
        likeables: ["Testimonial"],
      },
      {
        type: "Certificate",
        likeables: ["Certificate"],
      },
      {
        type: "Canteen Rent April/2022 to March/2023",
        likeables: ["Canteen Rent April/2022 to March/2023"],
      },
      {
        type: "Admission Form",
        likeables: ["Admission Form"],
      },
      {
        type: "Alumni Contribution",
        likeables: ["Alumni Contribution"],
      },
      { 
        type: "Reading Certificate", 
        likeables: ["Reading Certificate"]
    },
    ],
  },
];
