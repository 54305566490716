import { ADD_COURSES, ADD_SESSIONS, ADD_USER, MSG_STATUS, MSG_MESSAGE, MSG_CLOSE, LEAVE_BALANCE, DEPT_SUBJECT } from "../constants/action-types";
import { GetData } from "../../api/service";
import AuthService from "../../auth_components/js/AuthService";

const Auth = new AuthService;


export function getCourses() {
    return function (dispatch) {
        if (Auth.loggedIn()) {
            return GetData(`/getcourse`)
                .then((resp) => {
                    dispatch({ type: ADD_COURSES, payload: resp })
                })
        }
    };
}

export function getSessions() {
    return function (dispatch) {
        if (Auth.loggedIn()) {
            return GetData(`/getsession`)
                .then((resp) => {
                    dispatch({ type: ADD_SESSIONS, payload: resp })
                })
        }
    };
}


export function getMydetails() {
    return function (dispatch) {
        if (Auth.loggedIn()) {
            return GetData(`/getmydetails`)
                .then((resp) => {
                    dispatch({ type: ADD_USER, payload: resp })
                })
        }
    };
}


export function getMyLeaveBalance() {
    return function (dispatch) {
        if (Auth.loggedIn()) {
            return GetData(`/getemployeeleavebalance`)
                .then((resp) => {
                    if(resp){
                        dispatch({ type: LEAVE_BALANCE, payload: resp })
                    }
                    
                })
        }
    };
}


export function getMyDepartmentSubjects() {
    return function (dispatch) {
        if (Auth.loggedIn()) {
            return GetData(`/getsubjectsbydeptcode`)
                .then((resp) => {
                    if(resp){
                        dispatch({ type: DEPT_SUBJECT, payload: resp })
                    }
                    
                })
        }
    };
}


export function showMessage({ status, message }) {
    return function (dispatch) {
        dispatch({ type: MSG_STATUS, payload: status });
        dispatch({ type: MSG_MESSAGE, payload: message });

        setTimeout(() => dispatch({ type: MSG_CLOSE, payload: '' }), 2000);
    };
}
