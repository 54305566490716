import React, { Component } from 'react';
import withAuth from './../auth_components/js/withAuth';
import Navbar from '../Components/Navbar';
import './css/homepage.css';
import Welcome from '../Components/Homepage/Welcome';
import AuthService from '../auth_components/js/AuthService';

class HomePage extends Component {
  constructor(props) {
    super(props);

    let Auth = new AuthService;

        let user = Auth.getUser();
        let username = user.email;
        let name = user.name;

        this.state = {
            username: username,
            apikey: user.apikey,
            name: name,
            role: user.role,
            is_teaching: user.is_teaching
        }

   

  }
  render() {
    return (
      <div>
        <Navbar history={this.props.history} title="DashBoard" breadcumb="HOME / DashBoard" username={this.state.username} />
        <div className="main-body">

          <Welcome history={this.props.history} username={this.state.username} apikey={this.state.apikey} role={this.state.role} is_teaching={this.state.is_teaching} />  
        
        </div>
      </div>
    )
  }
}

export default withAuth(HomePage);