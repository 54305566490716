import React from 'react'
import { makeStyles, CircularProgress, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        top: 0,
        left: 0,
        width:'100vw',
        height: '100vh',
        backgroundColor: 'white',
        zIndex: 1031,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: "center",
        overflow: 'hidden'
    }
}))

export default function LoadingMain() {
    let classes = useStyles();
    return (
        <div className={classes.root}>
            <CircularProgress />
            <Typography variant="overline">
                Loading your App. Please Wait...
            </Typography>
        </div>
    )
}
