import React from 'react'
import { makeStyles, CircularProgress, Typography } from '@material-ui/core'
import { motion } from 'framer-motion'
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import { useSelector } from 'react-redux'
import { DEFAULT_COLOR } from '../../Helper/GlobalConstants';


const useStyles = makeStyles(theme => ({
    root: {
        position: "fixed",
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: DEFAULT_COLOR,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1300
    },
    div_motion: {
        textAlign: 'center'
    }
}))

export default function LargeAlert() {
    let classes = useStyles();

    const status = useSelector(state => state.msg_status);
    const message = useSelector(state => state.msg_message);
    if (status) {
        return (
            <div className={classes.root}>
                <Success message={message} />
            </div>
        )
    } else {
        return null;
    }

}





export function Success({ message }) {
    let classes = useStyles();
    return (
        <React.Fragment>
            <motion.div
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: 0.5, rotate: 360, opacity: 1 }}
                transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 30,

                }}
                className={classes.div_motion}
            >
                <div>
                    <AssignmentTurnedInOutlinedIcon
                        style={{ fontSize: 100, color: 'white' }}
                    />
                </div>
            </motion.div>
            <motion.div
                initial={{ scale: 0, opacity: 0.1 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20
                }}
            >
                <Typography
                    style={{ color: 'white' }}
                >
                    {message}
                </Typography>
            </motion.div>
        </React.Fragment>
    )
}