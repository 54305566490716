import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import { Drawer, Divider, List, ListItem, ListItemIcon, ListItemText, Collapse, Typography } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Dashboard from '@material-ui/icons/Dashboard';
import Message from '@material-ui/icons/Message';
import DateRange from '@material-ui/icons/DateRange';
import QueryBuilder from '@material-ui/icons/QueryBuilder';
import Receipt from '@material-ui/icons/Receipt';
import Label from '@material-ui/icons/Label';
import Money from '@material-ui/icons/Money';
import Person from '@material-ui/icons/Person';
import Notifications from '@material-ui/icons/Notifications';
import Work from '@material-ui/icons/Work';
import Description from '@material-ui/icons/Description';
import Assignment from '@material-ui/icons/Assignment';
import EventAvailable from '@material-ui/icons/EventAvailable';
import AcUnitIcon from '@material-ui/icons/AcUnit';

import AuthService from '../auth_components/js/AuthService';

import packageJson from './../package.alias.json'
import { DRAWER_WIDTH, APP_GOOD_NAME } from '../Helper/GlobalConstants';

const drawerWidth = DRAWER_WIDTH;

const styles = theme => ({
    root: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        backgroundColor: '#2196F3'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    toolbar: {
        ...theme.mixins.toolbar,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'


    },
    navIconHide: {
        display: 'block',
        [theme.breakpoints.up('xl')]: {
            display: 'none'
        }
    },
    drawerPaper: {
        width: drawerWidth,
        left: 0,
        [theme.breakpoints.up('lg')]: {
            width: drawerWidth
        }
    },
    listSmall: {
        paddingLeft: '35px'
    },
    version: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        textAlign: 'center',
        fontWeight:'bold',
        fontSize: '0.8em'
    },
    listItemIcon: {
        minWidth: '40px'
    },
    listItemText: {
        fontWeight: 500,
        color: '#1F2B3D'
    }
});





class DrawerMain extends React.Component {
    constructor(props) {
        super(props);

        let Auth = new AuthService;
        let user = Auth.getUser();
        let is_teaching = user.is_teaching;
        let name = user.name;
        let access = user.access;

        let menuListObject = [];

        if (is_teaching) {
            menuListObject = [
                {
                    title: "Dasboard",
                    link: "/",
                    submenu: [],
                    icon: <Dashboard />
                },
                {
                    title: "Apply Leave",
                    link: "/leave/apply",
                    submenu: [],
                    icon: <Description />
                },
                {
                    title: "Leave Status",
                    link: "/leave/status",
                    submenu: [],
                    icon: <EventAvailable />
                },
                {
                    title: "Log Book",
                    link: "/logbook",
                    submenu: [],
                    icon: <Receipt />
                },
                // {
                //     title: "Class Notes",
                //     link: "/classnotes",
                //     submenu: [],
                //     icon: <AcUnitIcon />
                // },
                {
                    title: "Activity Diary",
                    link: "/diary",
                    submenu: [],
                    icon: <Assignment />
                },
                {
                    title: "Class Routine",
                    link: "/routine",
                    submenu: [],
                    icon: <QueryBuilder />
                },
                // {
                //     title: "Assessment",
                //     link: "/classtest",
                //     submenu: [],
                //     icon: <EventAvailable />
                // },
                // {
                //     title: "Assessment Rep.",
                //     link: "/assesmentreport",
                //     submenu: [],
                //     icon: <Description />
                // },
                {
                    title: "Online Class",
                    link: "/onlineclass/classnotes",
                    submenu: [],
                    icon: <AcUnitIcon />
                },
                {
                    title: "Notifications",
                    link: "/notifications",
                    submenu: [],
                    icon: <Notifications />
                },
                // {
                //     title: "Assignment",
                //     link: "/assignment",
                //     submenu: [],
                //     icon: <Assignment />
                // },
                // {
                //     title: "Certificates",
                //     link: "/certificate",
                //     submenu: [],
                //     icon: <Message />
                // }

            ]
        } else {
            menuListObject = [
                {
                    title: "Dasboard",
                    link: "/",
                    submenu: [],
                    icon: <Dashboard />
                },
                {
                    title: "Apply Leave",
                    link: "/leave/apply",
                    submenu: [],
                    icon: <Description />
                },
                {
                    title: "Leave Status",
                    link: "/leave/status",
                    submenu: [],
                    icon: <EventAvailable />
                },
                {
                    title: "Notifications",
                    link: "/notifications",
                    submenu: [],
                    icon: <Notifications />
                },
                {
                    title: "Activity Diary",
                    link: "/diary",
                    submenu: [],
                    icon: <Assignment />
                },
                 {
                    title: "Certificates",
                    link: "/certificate",
                    submenu: [],
                    icon: <Message />
                }
            ]
        }


        // put access parameters

        if (Array.isArray(access) && access.length > 0) {
            access.map((el, index) => {

                if (el.access_field === 'ACC') {
                    let d = {
                        title: "Accounts",
                        link: "/accounts",
                        submenu: [],
                        icon: <Work />
                    }

                    menuListObject.push(d);
                }


                if (el.access_field === 'ADM') {
                    let d = {
                        title: "Admission",
                        link: "/admission",
                        submenu: [],
                        icon: <Label />
                    }

                    menuListObject.push(d);

                    let e = {
                        title: "Hostel Admission",
                        link: "/hostel",
                        submenu: [],
                        icon: <Label />
                    }

                    menuListObject.push(e);
                }

                if (el.access_field === 'FEE') {
                    let d = {
                        title: "Fees Collection",
                        link: "/fees/offlinefees",
                        submenu: [],
                        icon: <Money />
                    }

                    menuListObject.push(d);
                }

                if (el.access_field === 'REP') {
                    let d = {
                        title: "Reports",
                        link: "/reports",
                        submenu: [],
                        icon: <Person />
                    }

                    menuListObject.push(d);
                }

                if (el.access_field === 'LEAVEPRINCE') {
                    let d = {
                        title: "Approve Leave",
                        link: "/leavegrant",
                        submenu: [],
                        icon: <DateRange />
                    }

                    menuListObject.push(d);
                }

                if (el.access_field === 'CERTIFICATE') {
                    let d = {
                        title: "Certificate Generation",
                        link: "/certificate",
                        submenu: [],
                        icon: <DateRange />
                    }

                    menuListObject.push(d);
                }


                if (el.access_field === 'APPOINTMENT') {
                    let d = {
                        title: "Appointment",
                        link: "/appointment",
                        submenu: [],
                        icon: <Label />
                    }

                    menuListObject.push(d);

                }
            })
        }


        this.state = {
            anchorEl: null,
            mobileMoreAnchorEl: null,
            open: [false, false, false, false],
            menuListObject: menuListObject,
            name: name,
            version: packageJson.version
        }



    }

    handleClick = (e) => {
        let open = this.state.open;
        open[e] = !this.state.open[e];
        this.setState({
            open
        })
    }


    handleProfileMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
        this.handleMobileMenuClose();
    };

    handleMobileMenuOpen = event => {
        this.setState({ mobileMoreAnchorEl: event.currentTarget });
    };

    handleMobileMenuClose = () => {
        this.setState({ mobileMoreAnchorEl: null });
    };

    render() {
        const { anchorEl, mobileMoreAnchorEl } = this.state;
        const { classes } = this.props;




        const menuListObject = this.state.menuListObject;

        let menu = [];

        menu = menuListObject.map((el, index) => {

            let i = [];
            let j = [];
            if (el.submenu.length > 0) {
                if (this.state.open[index]) {
                    i = <ExpandLess />
                    j =
                        <Collapse in={this.state.open[index]} timeout="auto" unmountOnExit>
                            <List>
                                {el.submenu.map((el, index) =>
                                    <Link to={el.link} key={index}>
                                        <ListItem button className={classes.listSmall}>
                                            <ListItemText>
                                                <Typography variant="body1">{el.title}</Typography>
                                            </ListItemText>
                                        </ListItem>
                                    </Link>
                                )}
                            </List>
                        </Collapse>
                } else {
                    i = <ExpandMore />
                    j = [];
                }
            }
            return <List disablePadding key={index} >
                {(el.link !== "") ? <Link to={el.link}>
                    <ListItem button onClick={this.handleClick.bind(this, index)}>
                        <ListItemIcon className={classes.listItemIcon}>
                            {el.icon}
                        </ListItemIcon>
                        <ListItemText >
                            <Typography variant="body2" className={classes.listItemText}>{el.title}</Typography>
                        </ListItemText>
                        {(el.submenu.length > 0) ? i : null}
                    </ListItem>
                </Link> : <ListItem button onClick={this.handleClick.bind(this, index)}>
                        <ListItemIcon className={classes.listItemIcon}>
                            {el.icon}
                        </ListItemIcon>
                        <ListItemText >
                            <Typography className={classes.listItemText} variant="body2">{el.title}</Typography>
                        </ListItemText>
                        {(el.submenu.length > 0) ? i : null}
                    </ListItem>}
                {j}
            </List>
        }


        )



        return (
            <div >

                <Drawer
                    className={classes.drawer}
                    variant="permanent"
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    anchor="left"
                >

                    <div className={classes.toolbar}>
                    
                        <Typography>
                            {APP_GOOD_NAME}
                        </Typography>
                        <Typography variant="caption">
                            {this.state.name}
                        </Typography>
                    </div>
                    <Divider />
                    {menu}
                    <div className={classes.version}>
                        {`v ${this.state.version}`}
                    </div>
                </Drawer>
            </div>
        );
    }
}

DrawerMain.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DrawerMain);
