import React, { Component } from 'react';
import '../css/Login.css';
import Notifications, { notify } from 'react-notify-toast';
import AuthService from './AuthService';
import { Link } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Fade from '@material-ui/core/Fade';

import LinearProgress from '@material-ui/core/LinearProgress';
import { Typography, IconButton } from '@material-ui/core';
import { APP_GOOD_NAME } from '../../Helper/GlobalConstants';

const styles = theme => ({
    card: {
        maxWidth: 1000
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        marginLeft: 'auto',
        [theme.breakpoints.up('sm')]: {
            marginRight: -8,
        },
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    resize: {
        fontSize: 18
    },
    resizeLable: {
        fontSize: 15,
        fontWeight: 400
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        width: '100vw',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#2196F3'

    },
    content: {
        position: 'relative'
    },
    iconlogin: {
        backgroundColor: '#DFF2FC',
        position: 'absolute',
        zIndex: 2,
        top: 0,
        left: 0,
        height: '120px',
        width: '120px',
        marginLeft: '32%',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '-20%',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '30%'
        }
    },
    button: {
        backgroundColor: '#2196F3'
    }
});

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            isLoading: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.Auth = new AuthService();
    }
    componentWillMount() {
        if (this.Auth.loggedIn())
            this.props.history.replace('/');
    }
    onSubmit(e) {
        e.preventDefault();
        this.setState({
            isLoading: true
        })
        this.Auth.login(this.state.username, this.state.password)
            .then(res => {
                let username = this.state.username;
                this.setState({
                    username: "",
                    password: "",
                    isLoading: false
                });
                /* this.props.history.push({
                    state: { username: username, role: res.user.role },
                    pathname: '/'
                }); */
                window.location.reload();
            })
            .catch(err => {
                notify.show('Wrong Username or password', "error", 3000);
                this.setState({
                    isLoading: false
                })
            });
    }
    handleChange(e) {
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    }
    render() {
        const { classes } = this.props;
        return (
            <section className={classes.container} >
                <Notifications />

                <div >
                    <Fade in={true} timeout={{ enter: 1500 }}>
                        <section className="login">

                            <div align="center">
                                <Typography
                                    variant="h4"
                                    style={{color: 'white'}}
                                    gutterBottom
                                >
                                    {APP_GOOD_NAME}
                                </Typography>

                                <Typography
                                    variant="h6"
                                    style={{ color: 'white' }}
                                    gutterBottom
                                >
                                    Login System
                                </Typography>
                            </div>





                            <Card raised className={classes.card}>
                                {this.state.isLoading ? <LinearProgress /> : null}


                                <CardContent className={classes.content}>

                                    


                                    <div className="login-contents">
                                        <form onSubmit={this.onSubmit}>

                                            <FormControl fullWidth required>
                                                {/* <InputLabel htmlFor="adornment-password">Username</InputLabel> */}
                                                {/* <Input
                                        autoFocus
                                        required
                                        type='text'
                                        name="username"
                                        value={this.state.username}
                                        onChange={this.handleChange}
                                    /> */}

                                                <TextField
                                                    required

                                                    label="Email Id"
                                                    InputProps
                                                    name="username"
                                                    value={this.state.username}
                                                    onChange={this.handleChange}
                                                    margin="normal"
                                                    InputProps={{
                                                        classes: {
                                                            input: classes.resize,
                                                        },
                                                    }}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.resizeLable
                                                        }
                                                    }}
                                                />
                                            </FormControl>

                                            <br />






                                            <FormControl fullWidth required>
                                                <TextField
                                                    type="password"
                                                    required
                                                    id="standard-required"
                                                    label="Password"
                                                    InputProps
                                                    name="password"
                                                    value={this.state.password}
                                                    onChange={this.handleChange}
                                                    margin="normal"
                                                    InputProps={{
                                                        classes: {
                                                            input: classes.resize,
                                                        },
                                                    }}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.resizeLable
                                                        }
                                                    }}
                                                />
                                            </FormControl>


                                            <br />
                                            <br />
                                            <div align="right">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    className={classes.button}
                                                >
                                                    Login
                                        </Button>
                                            </div>



                                        </form>



                                    </div>

                                    <div style={{ textAlign: 'center' }}>
                                        <Link to="/forgotpassword">
                                        <Typography>
                                            Forgot password ?
                                    </Typography>
                                    </Link>
                                    </div>
                                </CardContent>
                            </Card>


                        </section>
                    </Fade>
                </div>
            </section>
        );
    }
}

export default withStyles(styles)(Login);

export const IconLogin = (props) => {
    let { classes } = props;
    return (
        <div className={classes.iconlogin}>

        </div>
    )
}