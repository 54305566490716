import React, { Component, Suspense } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from './history';
import './App.css';
import Login from './auth_components/js/Login';
import HomePage from './Containers/HomePage';
import LoadingMain from './LoadingMain';
import LargeAlert from './Components/Alerts/LargeAlert';
import ReceiptPrint from './Components/Fees/New/ReceiptPrint';
import "./fonts/Silentha OT.ttf"
import "./fonts/GreatVibes-Regular.ttf"
import "./fonts/DancingScript-Regular.ttf"






const SettingsContainer = React.lazy(() => import('./Containers/SettingsContainer'));
const ForgotPasswordContainer = React.lazy(() => import('./Containers/ForgotPasswordContainer'));
const AttendanceContainer = React.lazy(() => import('./Containers/AttendanceContainer'));
const FeedbackContainer = React.lazy(() => import('./Containers/FeedbackContainer'));
const AttendanceReportContainer = React.lazy(() => import('./Containers/AttendanceReportContainer'));
const RoutineContainer = React.lazy(() => import('./Containers/RoutineContainer'));
const LogBookContainer = React.lazy(() => import('./Containers/LogBookContainer'));
const DiaryContainer = React.lazy(() => import('./Containers/DiaryContainer'));
const ProtectedRoutes = React.lazy(() => import('./ProtectedRoutes'));


// additional modules
const AdmissionContainer = React.lazy(() => import('./Containers/AdmissionContainer'));
const HostelContainer = React.lazy(() => import('./Containers/HostelContainer'));
const FeesContainer = React.lazy(() => import('./Containers/FeesContainer'));
const ReportsContainer = React.lazy(() => import('./Containers/ReportsContainer'));
const LeaveGrantContainer = React.lazy(() => import('./Containers/LeaveGrantContainer'));
const CertificateContainer = React.lazy(() => import('./Containers/CertificateContainer'));
const ClassNotesContainer = React.lazy(() => import('./Containers/ClassNotesContainer'));
const ClasstestContainer = React.lazy(() => import('./Containers/ClasstestContainer'));
const AssesmentReportContainer = React.lazy(() => import('./Containers/AssesmentReportContainer'));
const AccountsContainer = React.lazy(() => import('./Containers/AccountsContainer'));
const AssignmentContainer = React.lazy(() => import('./Containers/AssignmentContainer'));
const NotificationContainer = React.lazy(() => import('./Containers/NotificationContainer'));
const OnlineClassContainer = React.lazy(() => import('./Containers/OnlineClassContainer'));
const AppointmentContainer = React.lazy(() => import('./Containers/AppointmentContainer'));


class App extends Component {

  render() {
    return (
      <Suspense fallback={<LoadingMain />}>
        <Router history={history} >
          <div>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/forgotpassword" component={ForgotPasswordContainer} />
              <Route exact path="/" render={(props) => <HomePage {...props} />} />
              <Route exact path="/settings" component={SettingsContainer} />
              <Route exact path="/attendance" component={AttendanceContainer} />
              <Route exact path="/takeattendance" component={AttendanceContainer} />
              <Route exact path="/editattendance" component={AttendanceContainer} />
              <Route exact path="/attendancereport" component={AttendanceContainer} />
              <Route exact path="/feedback" component={FeedbackContainer} />
              <Route exact path="/feedback/:id" component={FeedbackContainer} />

              <Route exact path="/reportattendance" component={AttendanceReportContainer} />
              <Route exact path="/routine" component={RoutineContainer} />
              <Route exact path="/logbook" component={LogBookContainer} />
              <Route exact path="/diary" component={DiaryContainer} />


              <Route exact path="/classnotes" component={ClassNotesContainer} />


              <Route exact path="/classtest" component={ClasstestContainer} />
              <Route exact path="/classtest/addmarks" component={ClasstestContainer} />
              <Route exact path="/classtest/singlereport" component={ClasstestContainer} />

              <Route exact path="/assesmentreport" component={AssesmentReportContainer} />

              <Route exact path="/admission" component={AdmissionContainer} />
              <Route exact path="/admission/verification" component={AdmissionContainer} />
              <Route exact path="/admission/verify/:form_id" component={AdmissionContainer} />
              <Route exact path="/admission/new" component={AdmissionContainer} />
              <Route exact path="/admission/newadmission" component={AdmissionContainer} />
              <Route exact path="/admission/tdcadmission" component={AdmissionContainer} />
              <Route exact path="/admission/view" component={AdmissionContainer} />
              <Route exact path="/admission/major" component={AdmissionContainer} />
              <Route exact path="/admission/shortlist" component={AdmissionContainer} />
              <Route exact path="/admission/fileview" component={AdmissionContainer} />
              <Route exact path="/admission/allidcards" component={AdmissionContainer} />
              <Route exact path="/admission/edit" component={AdmissionContainer} />
              <Route exact path="/admission/modified" component={AdmissionContainer} />
              <Route exact path="/admission/printform" component={AdmissionContainer} />
              <Route exact path="/admission/newstudents" component={AdmissionContainer} />
              <Route exact path="/admission/librarycard" component={AdmissionContainer} />
              <Route exact path="/admission/request" component={AdmissionContainer} />
              <Route exact path="/admission/checkrequest/:course/:stream" component={AdmissionContainer} />
              <Route exact path="/admission/checkresponse/:course/:stream" component={AdmissionContainer} />
              <Route exact path="/admission/taken/:course/:stream/:semester" component={AdmissionContainer} />
              <Route exact path="/admission/ackprint/:form_id" component={AdmissionContainer} />
              <Route exact path="/admission/ackprintre/:form_id" component={AdmissionContainer} />
              <Route exact path="/admission/studentimport" component={AdmissionContainer} />
              <Route exact path="/admission/semesterupdate" component={AdmissionContainer} />

              <Route exact path="/hostel" component={HostelContainer} />
              <Route exact path="/hostel/verification" component={HostelContainer} />
              <Route exact path="/hostel/verifiedlist" component={HostelContainer} />
              <Route exact path="/hostel/verify/:form_id" component={HostelContainer} />



              <Route exact path="/fees" component={FeesContainer} />
              <Route exact path="/fees/receipts" component={FeesContainer} />
              <Route exact path="/fees/readmission" component={FeesContainer} />
              <Route exact path="/fees/editreceipt" component={FeesContainer} />
              <Route exact path="/fees/rawreceipt" component={FeesContainer} />
              <Route exact path="/fees/reports" component={FeesContainer} />
              <Route exact path="/fees/singlereceipt" component={FeesContainer} />
              <Route exact path="/fees/singlereceiptprint/:receipt_no" render={(props) => <ReceiptPrint receipt_no={props.match.params.receipt_no} />} />
              <Route exact path="/fees/headwise" component={FeesContainer} />
              <Route
                  exact
                  path="/fees/offlinefees"
                  component={FeesContainer}
                />
                <Route
                  exact
                  path="/fees/printreceiptall"
                  component={FeesContainer}
                />
                
                <Route exact path="/fees/payfees" component={FeesContainer} />

              <Route exact path="/leavegrant" component={LeaveGrantContainer} />

              <Route exact path="/reports" component={ReportsContainer} />
              <Route exact path="/reports/newstudents" component={ReportsContainer} />
              <Route exact path="/reports/idcards" component={ReportsContainer} />
              <Route exact path="/reports/allidcards" component={ReportsContainer} />

              <Route exact path="/certificate" component={CertificateContainer} />
              <Route exact path="/certificate/certificatetype" component={CertificateContainer} />
              <Route exact path="/certificate/certificateview" component={CertificateContainer} />
              <Route exact path="/certificate/view" component={CertificateContainer} />
              <Route exact path="/certificate/reports" component={CertificateContainer} />
              <Route exact path="/certificate/print" component={CertificateContainer} />


              <Route exact path="/accounts" component={AccountsContainer} />
              <Route exact path="/accounts/accountheads" component={AccountsContainer} />
              <Route exact path="/accounts/accountsubheads" component={AccountsContainer} />
              <Route exact path="/accounts/payments" component={AccountsContainer} />
              <Route exact path="/accounts/opbalancehead" component={AccountsContainer} />
              <Route exact path="/accounts/opbalanceaccounts" component={AccountsContainer} />
              <Route exact path="/accounts/cashbook" component={AccountsContainer} />
              <Route exact path="/accounts/collection-report" component={AccountsContainer} />
              <Route exact path="/accounts/printvoucher" component={AccountsContainer} />
              <Route exact path="/accounts/cashbookfinal" component={AccountsContainer} />
              <Route exact path="/accounts/reports" component={AccountsContainer} />
              <Route exact path="/accounts/view" component={AccountsContainer} />
              <Route exact path="/accounts/taxcollection" component={AccountsContainer} />


              <Route exact path="/assignment" component={AssignmentContainer} />
              <Route exact path="/assignment/check" component={AssignmentContainer} />

              <Route exact path="/onlineclass/assignment" component={OnlineClassContainer} />
                <Route exact path="/onlineclass/assignment/check" component={OnlineClassContainer} />
              <Route exact path="/onlineclass/classnotes" component={OnlineClassContainer} />
              <Route exact path="/onlineclass/assesment" component={OnlineClassContainer} />
              <Route exact path="/onlineclass/assesmentreport" component={OnlineClassContainer} />
              <Route exact path="/onlineclass/classtest/addmarks" component={OnlineClassContainer} />
              <Route exact path="/onlineclass/classtest/singlereport" component={OnlineClassContainer} />

              <Route exact path="/onlineclass/examination" component={OnlineClassContainer} />
             
             <Route exact path="/onlineclass/examination/add" component={OnlineClassContainer} />
             <Route exact path="/onlineclass/examination" component={OnlineClassContainer} />
               <Route exact path="/onlineclass/examination/add" component={OnlineClassContainer} />
               <Route exact path="/onlineclass/examination/addquestions" component={OnlineClassContainer} />
               <Route exact path="/onlineclass/examination/results" component={OnlineClassContainer} />
               <Route exact path="/onlineclass/examination/print" component={OnlineClassContainer} />



               <Route exact path="/appointment" component={AppointmentContainer} />
               <Route exact path="/appointment/reports" component={AppointmentContainer} />

              <ProtectedRoutes pp={this.props} />
            </Switch>

            <LargeAlert />
          </div>
        </Router>
      </Suspense>
    );
  }
}

export default App;
