import React, { Component } from 'react'
import { Card, CardHeader, Typography, Avatar, CardContent, Select, Grid, withStyles, CardActions, Button, Dialog, DialogTitle, List, ListItem, ListItemAvatar, ListItemText, DialogActions, IconButton, DialogContent, TextField, Divider } from '@material-ui/core';
import Report from '@material-ui/icons/Report'
import { GetData, PostData } from '../../api/service';
import { green, red } from '@material-ui/core/colors';
import AnalogClock, { Themes } from 'react-analog-clock';
import ExtraClassModal from './ExtraClassModal';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Skeleton } from '@material-ui/lab'
import NewClock from './NewClock';
import {Result} from 'antd'
import {SmileOutlined} from '@ant-design/icons'

const styles = theme => ({
    topBar: {
        width: '100%',
        height: '4px',
        backgroundColor: 'red'
    },
    noClassesDiv: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: red[100],
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        width: '100%'
    },
    buttonClass: {

    },
    cardActionDiv: {
        display: 'flex',
        flexDirection: 'row'
    }
})

class RoutineLanding extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isLoadingT: true,
            routine: [],
            routineT: [],
            extra: false,
            assign_data: [],
            assign_status: false,
            other_teachers: [],
            isLoadingA: true,
            assignedClasses: [],
            cancelClick: false,
            cancel_data: [],
            cancel_reason: '',
            is_holiday: null,
            holiday: ''
        }

    }

    __loadData = () => {
        GetData(`/${this.props.apikey}/${this.props.username}/getmyclasses`)
            .then((resp) => {
                //console.log(resp)
                this.setState({
                    isLoading: false,
                    routine: resp
                })
            })



        GetData(`/${this.props.apikey}/${this.props.username}/getmyassignedclasses`)
            .then((resp) => {
                //console.log(resp)
                if (Array.isArray(resp) && resp.length > 0) {
                    this.setState({
                        isLoadingA: false,
                        assignedClasses: resp
                    })
                }
            })



        GetData(`/${this.props.apikey}/${this.props.username}/getmyclassestomorrow`)
            .then((resp) => {
                //console.log(resp)
                this.setState({
                    isLoadingT: false,
                    routineT: resp
                })
            })


            GetData(`/istodayholiday`)
            .then((resp) => {
                if(resp != null){
                    this.setState({
                        is_holiday: true,
                        holiday: resp
                    })
                }else{
                    this.setState({
                        is_holiday: false,
                        holiday: ''
                    })
                }
                
            })
    }

    componentDidMount() {
        this.__loadData();
    }


    takeAttendance = (el) => {
        let data = {
            course: el.course,
            course_type: el.course_type,
            dept_code: el.dept_code,
            semester: el.semester,
            stream: el.stream,
            sub_code: el.sub_code,
            period_number: el.period_number,
            section: el.section,
            start_time: el.start_time,
            end_time: el.end_time,
        }

        this.props.history.push('/takeattendance', data);
    }

    attendanceReport = (el) => {
        let data = {
            course: el.course,
            dept_code: el.dept_code,
            semester: el.semester,
            stream: el.stream,
            sub_code: el.sub_code,
            section: el.section,
        }

        this.props.history.push('/attendancereport', data);
    }

    onExtraClassClick = () => {
        this.setState({
            extra: !this.state.extra
        })
    }

    onAssignment = (el) => {
        let assign_data = {
            course: el.course,
            course_type: el.course_type,
            dept_code: el.dept_code,
            semester: el.semester,
            stream: el.stream,
            sub_code: el.sub_code,
            period_number: el.period_number,
            assigned_by: this.props.username,
            session: el.session,
            day: el.day,
            room_number: el.room_number,
            start_time: el.start_time,
            end_time: el.end_time,
            section: el.section,
        }

        //fetch departmental teacher list from here

        GetData(`/${el.dept_code}/getemployeedeptforassign`)
            .then((resp) => {
                this.setState({
                    assign_data,
                    assign_status: true,
                    other_teachers: resp
                })
            })


    }

    handleCloseDialog = () => {
        this.setState({
            assign_status: false,
            assign_data: [],
            other_teachers: []
        })
    }

    assignNewTeacher = (el) => {
        this.setState({
            assign_status: false
        }, () =>
            confirmAlert({
                title: 'Confirm to submit',
                message: 'Are you sure to assign this class to ' + el.name,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: this.onConfirmAssignNewTeacher.bind(this, el)
                    },
                    {
                        label: 'No'
                    }
                ]
            })
        )

    }

    onConfirmAssignNewTeacher = (el) => {

        let assign_data = this.state.assign_data;

        const data = {
            ...assign_data,
            teacher: el.email
        }

        PostData(`/${this.props.apikey}/${this.props.username}/assignclasstoother`, data)
            .then((resp) => {
                confirmAlert({
                    title: 'Successful',
                    message: resp,
                    buttons: [
                        {
                            label: 'OKAY',
                            onClick: this.__loadData()
                        }
                    ]
                })
            })
    }

    onCancel = (el) => {
        this.setState({
            cancelClick: true,
            cancel_data: el
        })
    }

    handleCloseCancel = () => {
        this.setState({
            cancelClick: false,
            cancel_data: []
        })
    }

    onReasonChange = (e) => {

        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onCancelSubmit = () => {
        if (this.state.cancel_reason !== '') {

            let cancel_data = this.state.cancel_data;

            let data = {
                ...cancel_data,
                reason: this.state.cancel_reason
            }

            // Ask for confirmation

            this.setState({
                cancelClick: false
            }, () => {
                confirmAlert({
                    title: 'Confirm to submit',
                    message: 'Are you sure to do this.',
                    buttons: [
                        {
                            label: 'Yes',
                            onClick: this.onConfimCancel.bind(this, data)
                        },
                        {
                            label: 'No'
                        }
                    ]
                })
            })


        }
    }

    onConfimCancel = (data) => {
        PostData(`/${this.props.apikey}/${this.props.username}/cancelclass`, data)
            .then((resp) => {
                confirmAlert({
                    title: 'Successfully Cancelled',
                    message: resp,
                    buttons: [
                        {
                            label: 'Okay',
                            onClick: () => this.__loadData()
                        }
                    ]
                })
            })
    }


    render() {
        let { classes } = this.props;
        let i = [];
        let j = [];
        if (!this.state.isLoading) {
            let r = this.state.routine;
            //console.log(r)
            if (Array.isArray(r) && r.length > 0) {
                i = r.map((el, index) =>
                    <Grid item xs={12} lg={4} md={4} sm={12} key={index}>
                        <RoutineCard
                            index={index + 1}
                            {...el}
                            {...this.props}
                            takeAttendance={this.takeAttendance.bind(this, el)}
                            attendanceReport={this.attendanceReport.bind(this, el)}
                            onAssignment={this.onAssignment.bind(this, el)}
                            onCancel={this.onCancel.bind(this, el)}
                        />
                    </Grid>
                )
            } else {
                i = <NoClassesFound {...this.props} />
            }
        } else {
            i = [1, 2].map((el, index) =>
                <Grid item xs={12} lg={4} md={4} sm={12} key={index}>
                    
                    <Skeleton variant="rect" style={{ height: '200px' }} />
                </Grid>
            )
        }



        if (!this.state.isLoadingA) {
            let s = this.state.assignedClasses;
            //console.log(r)
            if (Array.isArray(s) && s.length > 0) {
                j = s.map((el, index) =>
                    <Grid item xs={12} lg={4} md={4} sm={12} key={index}>
                        <RoutineCard
                            index={index + 1}
                            {...el}
                            {...this.props}
                            takeAttendance={this.takeAttendance.bind(this, el)}
                            attendanceReport={this.attendanceReport.bind(this, el)}
                            onAssignment={this.onAssignment.bind(this, el)}
                            onCancel={this.onCancel.bind(this, el)}
                        />
                    </Grid>
                )
            } else {
                j = <NoClassesFound {...this.props} />
            }
        } else {
            j = [1, 2].map((el, index) =>
                <Grid item xs={12} lg={4} md={4} sm={12} key={index}>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton style={{ height: '220px' }} />
                </Grid>
            )
        }



        let k = [];
        if (!this.state.isLoadingT) {
            let r1 = this.state.routineT;
            //console.log(r)
            if (Array.isArray(r1) && r1.length > 0) {
                k = r1.map((el, index) =>
                    <Grid item xs={12} lg={4} md={4} sm={12} key={index}>
                        <RoutineCardShort
                            index={index + 1}
                            {...el}
                            {...this.props}
                        />
                    </Grid>
                )
            } else {
                k = <NoClassesFound {...this.props} />
            }
        } else {
            k = [1, 2].map((el, index) =>
                <Grid item xs={12} lg={4} md={4} sm={12} key={index}>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton style={{ height: '220px' }} />
                </Grid>
            )
        }
        return (
            <div>
                <Card>
                    <CardHeader
                        avatar={
                            // <AnalogClock theme={Themes.light} width={70} />
                            <NewClock />
                        }

                        title={
                            <Typography
                                variant="h5"
                            >
                                Today's Classes
                    </Typography>
                        }
                        subheader={
                            `Class Routine`
                        }
                    />

                    {this.state.is_holiday === true && <Result
                        icon={<SmileOutlined />}
                        title={`Today is holiday  (${this.state.holiday})`}
                        
                    />}


                    {/* <CardActions>
                        <Button
                            size="small"
                            color="primary"
                            onClick={this.onExtraClassClick}
                        >
                            ADD EXTRA CLASS
                        </Button>
                    </CardActions> */}
                </Card>
                <br />

                {this.state.is_holiday == false && <Grid container spacing={2}>
                    {i}
                </Grid>}


                {this.state.cancelClick ?
                    <CcancelDialog
                        open={this.state.cancelClick}
                        handleCloseCancel={this.handleCloseCancel}
                        onReasonChange={this.onReasonChange}
                        cancel_reason={this.state.cancel_reason}
                        onCancelSubmit={this.onCancelSubmit}
                    /> : null}

                {this.state.extra ? <ExtraClassModal history={this.props.history} handleClose={this.onExtraClassClick} /> : null}



                {/* For class assignements */}

                {this.state.assign_status ? <AssignDialog
                    open={this.state.assign_status}
                    handleClose={this.handleCloseDialog}
                    other_teachers={this.state.other_teachers}
                    assignNewTeacher={this.assignNewTeacher.bind(this)}
                /> : null}

                <br />

                {!this.state.isLoadingA ?
                    <React.Fragment>
                        <Card>
                            <CardHeader
                                avatar={

                                    <AnalogClock theme={Themes.light} width={100} />

                                }

                                title={
                                    <Typography
                                        variant="h5"
                                    >
                                        Today's Classes
                    </Typography>
                                }
                                subheader={
                                    `Assigned Classes`
                                }
                            />
                            <CardContent>

                            </CardContent>


                        </Card>
                        <br />

                        <Grid container spacing={2}>
                            {j}
                        </Grid>
                    </React.Fragment> : null}



                <br />
                {/* Tomorrows classes */}


                <Card>
                    <CardHeader
                        title={
                            <Typography
                                variant="h5"
                            >
                                Tomorrow's Classes
                    </Typography>
                        }
                        subheader={`Class Routine`}

                    />
                   
                </Card>
                <br />

                <Grid container spacing={2}>
                    {k}
                </Grid>





            </div>
        )
    }
}


export default withStyles(styles)(RoutineLanding)


const RoutineCard = (props) => {
    let { classes } = props;

    const goodSemester = (semester) => {
        if(semester == 1){
            return '1st';
        }else if(semester == 2){
            return '2nd';
        }else if(semester == 3){
            return '3rd';
        }else{
            return `${semester}th`
        }
    }

    return (
        <Card raised>
            <div className={classes.topBar}></div>

            <CardContent style={{
                display: 'flex',
                width: '100%'
            }}>
                <div>


                    <Typography variant="body2">
                        {props.day}
                    </Typography>
                    <Typography variant="h6">
                        Period {props.period_number}
                    </Typography>

                    <Typography variant="body1">
                        {props.start_time} - {props.end_time}
                    </Typography>

                    <Typography variant="body2">
                        {props.sub_name}
                    </Typography>

                    <Typography>
                        {goodSemester(props.semester)} {(props.course == 'HSA' || props.course == 'HSS') ? 'Year' : 'Semester'}, {props.course}
                    </Typography>

                    {(props.section !== null) ?
                        <Typography variant="body1">
                            Section: {props.section}
                        </Typography> : null}

                    <Typography variant="body1">
                        Room: {props.room_number}
                    </Typography>

                </div>

            </CardContent>

            <Divider />

            <CardActions>
                {props.cancel === 0 ?
                    <div className={classes.cardActionDiv}>
                        {props.assignment === 0 ? <Button
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={props.takeAttendance}
                            disabled={props.status}
                        >
                            Take attendance
                </Button> : null}


                            &nbsp;

                        {props.assignment === 0 ?

                            <Button
                                size="small"
                                color="inherit"
                                variant="outlined"
                                onClick={props.onAssignment}
                                className={classes.buttonClass}
                                disabled={props.status}
                            >
                                Class Assignment
                                 </Button>

                            :
                            <Button
                                size="small"
                                color="inherit"
                                className={classes.buttonClass}
                            >
                                Assigned
                                 </Button>

                        }
                        &nbsp;

                        {(props.cancel === 0 && props.assignment === 0 && props.status !== true) ?
                            <Button
                                onClick={props.onCancel}
                                size="small"
                                color="secondary"
                                variant="outlined"
                            >
                                Cancel
                            </Button>

                            : null}

                    </div> :

                    <Typography
                        color="secondary">
                        Cancelled
                    </Typography>

                }


            </CardActions>
        </Card>
    )
}



const RoutineCardShort = (props) => {
    let { classes } = props;

    const goodSemester = (semester) => {
        if(semester == 1){
            return '1st';
        }else if(semester == 2){
            return '2nd';
        }else if(semester == 3){
            return '3rd';
        }else{
            return `${semester}th`
        }
    }

    
    return (
        <Card>
            <div className={classes.topBar}></div>
            <CardContent>
                <Typography variant="body2">
                    {props.day}
                </Typography>



                <Typography variant="h6">
                    Period {props.period_number}
                </Typography>

                <Typography variant="body1">
                    {props.start_time} - {props.end_time}
                </Typography>

                <Typography variant="body2">
                    {props.sub_name}
                </Typography>

                <Typography>
                        {goodSemester(props.semester)} {(props.course == 'HSA' || props.course == 'HSS') ? 'Year' : 'Semester'}, {props.course}
                    </Typography>

                {(props.section !== null) ?
                    <Typography variant="body1">
                        Section: {props.section}
                    </Typography> : null}

                <Typography variant="body1">
                    Room: {props.room_number}
                </Typography>
            </CardContent>
        </Card>
    )
}

const AssignDialog = (props) => {
    let { classes } = props;
    let i = [];
    if (props.open) {
        let j = props.other_teachers;
        if (Array.isArray(j) && j.length > 0) {
            i = j.map((el, index) =>

                <ListItem dense button key={index} onClick={props.assignNewTeacher.bind(this, el)}>
                    <ListItemAvatar>
                        <Avatar>

                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={el.name} secondary={el.email} />
                </ListItem>

            )
        }
    }
    return (
        <div>
            <Dialog open={props.open} aria-labelledby="simple-dialog-title">
                <DialogTitle id="simple-dialog-title">Assign Class to other teacher</DialogTitle>
                <div>
                    <List dense>
                        {i}
                    </List>
                </div>
                <DialogActions>
                    <Button color="primary" onClick={props.handleClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}


const NoClassesFound = (props) => {
    let { classes } = props;
    return (
        <div className={classes.noClassesDiv}>

            <Report />
            <Typography
                variant="body1"
                color="inherit"
            >
                &nbsp; No classes available
                </Typography>
        </div>
    )
}

const CcancelDialog = (props) => {
    let { classes } = props;
    return (
        <div>
            <Dialog open={true} aria-labelledby="simple-dialog-title">
                <DialogTitle id="simple-dialog-title">Please state the reason for cancellation</DialogTitle>
                <DialogContent>
                    <TextField
                        id="outlined-password-input"
                        label="State your reason here"
                        type="text"
                        margin="normal"
                        variant="outlined"
                        multiline
                        rows="5"
                        fullWidth
                        name="cancel_reason"
                        onChange={props.onReasonChange}
                        value={props.cancel_reason}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={props.handleCloseCancel}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={props.onCancelSubmit} >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}